.header-switches{
  display: grid;
  justify-content: end;
  grid-auto-flow: column;
  align-items: center;
  gap: 1rem;
}

#menu-toggler{
    background: transparent;
    border: none;
    width: 40px;
    height: 40px;
    position: relative;
    border-radius: 3px;
    transform: scale(.8);
  }
  
  #menu-toggler:hover{
    cursor: pointer;
  }
  
  #menu-toggler .line{
    width: 32px;
    height: 2px;
    display: inline-block;
    background: var(--c-dark-icons);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: .2s transform;
  }
  
  #menu-toggler .line:nth-of-type(1){
    width: 16px;
    transform: translate(-100%, -12px);
  }
  
  #menu-toggler .line:nth-of-type(3){
    width: 16px;
    transform: translate( 0%, 9px);
  }
  
  #menu-toggler.close .line:nth-of-type(1){
    transform: translate(-15px, -7px) rotate(45deg);
  }
  
  #menu-toggler.close .line:nth-of-type(2){
    transform: translate(-50%,-50%) rotate(-45deg);
  }
  
  #menu-toggler.close .line:nth-of-type(3){
    transform: translate(-3px, 5px) rotate(45deg);
  }
  