.hero img{
    width: 100%;
    padding: 0;
}

.hero .join-us{
    display: inline-block;
    margin-top: 1rem;
}

.hero .join-us a{
    background: var(--c-dark-highlight);
    color: var(--c-dark-bg);
    font-weight: 600;
    padding: .5rem 1rem;
}

