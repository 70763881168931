.contact-us .profile .details .info{
    background: none;
    padding: 0;
} 

.btn-tiny a{
    background: var(--c-dark-highlight);
    color: var(--c-dark-bg);
    padding: .3rem 1rem;
    border-radius: 50px;
    font-weight: bold;
}

.contact-us .details .link a{
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 1rem;
    color: var(--c-dark-text);
}

.contact-us .details .link{
    margin: 1rem 0;
    background: var(--c-dark-transparent);
    border-radius: 50px;
}

.contact-us .details .link img{
    width: 1rem; 
}

.contact-us .details .link .img{
    width: 2rem;
    height: 2rem;
    border-radius: 50px;
    background: var(--c-dark-highlight);
    display: grid;
    place-content: center;
}
