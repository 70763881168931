.social{
    display: grid;
    grid-auto-flow: column;
    place-content: center;
    gap: 1rem;
}

.social a{
    display: inline-block;
    width: 2.6em;
    height: 2.6em;
    background: var(--c-dark-bg);
    place-content: center;
    display: grid;
    place-content: center;
    border-radius: 50px;
}

.social a img{
    height: 1.2em;
}

.social .anim-social{
    transform: translateY(20px);
    opacity: 0;
    animation: 1s social-pop forwards;
}
.social .anim-social:nth-of-type(2){ animation-delay: .2s; }
.social .anim-social:nth-of-type(3){ animation-delay: .3s; }

@keyframes social-pop{
    to{
        transform: translateY(0px);
        opacity: 1;
    }
}