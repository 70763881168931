
.profile{
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 1rem;
    padding: 1rem 0;
}

.profile .image{
    width: 60px;
    height: 60px;
}

.profile .image img{
    width: 60px;
    height: 60px;
    border-radius: 50px;
    object-fit: cover;
    box-shadow: 0 0 0 2px var(--c-dark-highlight);
}

.profile .name{
    font-weight: 600;
}

.profile .details .info{
    background: var(--c-dark-transparent);
    border-radius: 9px;
    font-size: 14px;
    margin-top: 1rem;
    padding: 1em;
}