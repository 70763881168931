*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html{
  scroll-behavior: smooth;
}

:root{
  --c-dark-bg: #001;
  --c-dark-text: #fff;
  --c-dark-icons: #fff;
  --c-dark-highlight: #ff0;
  --c-dark-highlight2: #0ff;
  --c-dark-transparent: #fff2;
  --padding-small: 16px;
  --padding-large: 24px;
  --header-height: 70px;
  --media-query-width: 600px;
}

body{
  background: var(--c-dark-bg);
  color: var(--c-dark-text);
  font-family: 'Poppins', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.App{
  padding-top: var(--header-height);
  overflow: hidden;
}

.container{
  display: grid;
  place-items: center;
}

.wrapper{
  width: 100%;
  max-width: 1280px;
  padding: var(--padding-large);
}

img{
  max-width: 1280px;
}

a{
  text-decoration: none;
}

.text-dancing{
  font-family: 'Dancing Script', cursive;
  color: var(--c-dark-highlight);
  font-size: 1.6rem;
  line-height: 2;
}

.text-large{
  font-size: 2rem;
  line-height: 1.2;
}

.text-center{
  text-align: center;
}

.text-info{
  font-weight: 200;
  padding-top: 1rem;
}

.text-outline{
  color: var(--c-dark-bg);
  text-shadow: 1px 1px var(--c-dark-text),
              -1px -1px var(--c-dark-text),
              -1px 1px var(--c-dark-text),
              1px -1px var(--c-dark-text);
}

.text-info span{
  display: inline-block;
}

.focus-content{
  padding-top: 4rem;
  padding-bottom: 4rem;
}

