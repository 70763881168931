.text-cta{
    font-weight: bold;
}

.text-cta span{
    color: var(--c-dark-highlight);
}

.footer-cta{
    margin-bottom: 1rem;
}

.footer-cta .join-us{
    display: inline-block;
    margin-top: 1.2rem;
}

.footer-cta .join-us a{
    color: var(--c-dark-bg);
    background: var(--c-dark-highlight);
    font-weight: bold;
}