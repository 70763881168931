.our-mission .wrapper-vid{
    display: grid;
    place-content: center;
    position: relative;
}

.our-mission .wrapper-vid video{
    cursor: pointer;
    width: 100%;
    max-width: 720px;
    justify-self: center;
}

.our-mission .wrapper-vid .control{
    width: 80px;
    height: 80px;
    background: #fff;
    border-radius: 50%;
    border: 2px solid #0004;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: .4s opacity;
    opacity: .8;
}

.our-mission .wrapper-vid  .control.hidden{
    opacity: 0;
}

.our-mission .wrapper-vid .control::after{
    content: " ";
    width: 0;
    height: 0;
    border: 15px solid transparent;
    border-left: 24px solid #000;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-10px, -50%);
}
