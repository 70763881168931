.events{
    display: grid;
    grid-template-columns: auto 1fr;
}

.events .month{
    font-weight: bold;
    padding: .5rem 1rem .5rem 0;
    font-size: 1rem;
    color: var(--c-dark-highlight);
    border-right: 2px solid var(--c-dark-highlight);
    position: relative;
}

.events .month::after{
    content: " ";
    height: 10px;
    width: 10px;
    background: var(--c-dark-bg);
    border: 2px solid var(--c-dark-highlight);
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    right: -8px;
    top: calc(1rem - 2px);
}

.events .details {
    background: var(--c-dark-transparent);
    border-radius: 9px;
    padding: .5rem 1rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
}

.events .bottom-dotted-line{
    font-size: 12px;
    padding: calc(1rem + 2px);
    border-bottom: 2px dotted var(--c-dark-highlight);
    border-left: 2px dotted var(--c-dark-highlight);
    border-bottom-left-radius: 12px;
    transform: translateX(-2px);
}

.events .bottom-dotted-line a{
    color: var(--c-dark-highlight);
    font-weight: bold;
    display: inline-block;
    padding: .2rem .6rem;
    background: var(--c-dark-transparent);
    border: 1px solid var(--c-dark-highlight);
    border-radius: 50px;
}




/* past events */


.past-events{
    display: grid;
}

.past-events .month{
    background: #ff0;
    color: #000;
    border-radius: 50px;
    font-weight: bold;
    padding: .2rem 1rem;
    display: inline;
}

.past-events .details {
    background: var(--c-dark-transparent);
    border-radius: 9px;
    padding: 1rem;
    margin-bottom: 1rem;
    display: grid;
    place-items: start;
    gap: 1rem
}

.past-events .description-text {
    font-weight: bold;
    text-transform: uppercase;
}

.past-events .details .images {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-content: center;
    gap: 1rem;
}

.past-events .details .images img {
    display: block;
    width: 100%;
    border-radius: 1rem;
}

.past-events .details .images img:first-child{
    grid-column: span 2;
}

@media screen and (min-width: 768px) {
    .past-events .details .images {
        grid-template-columns: repeat(4, 1fr);
    }

    .past-events .details .images img:first-child{
        grid-column: span 4;
    }
    
}