.slider-container{
    width: 100%;
    display: grid;
    justify-content: center;
}

.slider-container .wrapper{
    width: 100%;
    max-width: 1280px;;
}

.slide{
    overflow: hidden;
    padding: 0 .5rem;
    outline: none;
}

.slide img{
    height: 240px;
    border-radius: 9px;
}

.slick-dots li button::before{
    color: var(--c-dark-text);
}

.slick-dots li.slick-active button::before{
    color: var(--c-dark-highlight);
    opacity: 1;
}

