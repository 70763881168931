@media screen and (min-width: 780px){
    :root{
        --padding-small: 16px;
        --padding-large: 24px;
        --header-height: 70px;
    }

    .team .profiles,
    .contact-us .wrapper .profiles{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }

    .contact-us .wrapper .profiles,
    .contact-us .contact-pt{
        margin-top: 1rem;
    }

    .contact-us .contact-pt{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
    }

    .contact-us .wrapper .profiles .profile{
        background: var(--c-dark-transparent);
        padding: 2rem;
        border-radius: 1rem;
    }

    .team .profiles .info{
        height: calc(100% - 60px);
    }

    .text-large{
        font-size: 3.2rem;
        line-height: 1.2;
    }

    .text-info{
        font-size: 1.4rem;
    }

    .focus-content{
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
}
