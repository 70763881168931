header {
    background: var(--c-dark-bg);
    height: var(--header-height);
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 9999;
}

header .wrapper{
    display: grid;
    align-items: center;
    grid-template-columns: 1fr auto;
    grid-template-rows: 1fr;
    justify-content: space-between;
    padding: 0 24px;
}

header .logo{
    line-height: 0;
}

header .logo img{
    height: 1.4rem;
}

header #main-nav{
    background: var(--c-dark-bg);
    text-align: left;
    display: none;
    position: fixed;
    width: 100%;
    top: var(--header-height);
    left: 0;
    bottom: 0;
}

header.shown #main-nav{
    display: grid;
    justify-items: center;
}

#main-nav .wr{
    width: 100%;
    max-width: 1280px;
    padding: 2rem 24px;
    display: grid;
    justify-content: center;
}

header #main-nav .links a{
    display: block;
    font-size: 2rem;
    text-align: center;
    border-radius: 50px;
    padding: 1rem .2rem;
    text-decoration: none;
    color: var(--c-dark-text);
    position: relative;
}

header #main-nav .links a:hover,
header #main-nav .links a:active{
    color: var(--c-dark-highlight);
}

nav ul {
    list-style-type: none;
}

header #main-nav .links a::after{
    content: "";
    display: inline-block;
    width: 0%;
    position: absolute;
    border-bottom: 2px solid var(--c-dark-highlight);
    top: 50%;
    left: 0;
    transition: .6s width;
}

header #main-nav .links a:hover::after,
header #main-nav .links a.active::after{
    width: 100%;
    opacity: 1;
}

header #main-nav .links a.active{
    color: var(--c-dark-highlight);
}

header #main-nav .social{
    margin-top: 1rem;
    border-top: 1px solid #fff;
    padding-top: 2rem;
}

header #main-nav .social a{
    background: var(--c-dark-text);
}

@media screen and (min-width:600px){
    header .header-switches,
    header #main-nav .social{
        display: none;
    }

    header #main-nav .wr,
    header #main-nav,
    header #main-nav ul{
        display: grid;
        position: relative;
        grid-auto-flow: column;
        gap: 2rem;
        margin: 0;
        padding: 0;
        font-size: 14px;
        top: auto;
    }

    header #main-nav .links ul li,
    header #main-nav .links ul li a{
        display: inline-block;
        width: auto;
        line-height: 1;
        font-size: 14px;
    }

    header #main-nav .links a::after{
        border-bottom: 1px solid var(--c-dark-highlight);
        opacity: 0;
    }
    
    header .logo img{
        height: 1.6rem;
    }
}



/* Nav Custom Animations */

header #main-nav ul li{
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
    animation: 1s in-frame forwards;
    transform: translateY(-40px);
    opacity: 0;
}

header #main-nav ul li:nth-of-type(2){ animation-delay: .1s; }
header #main-nav ul li:nth-of-type(3){ animation-delay: .2s; }
header #main-nav ul li:nth-of-type(4){ animation-delay: .3s; }
header #main-nav ul li:nth-of-type(5){ animation-delay: .4s; }
header #main-nav ul li:nth-of-type(6){ animation-delay: .5s; }

@keyframes in-frame{
    to{
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
        transform: translateY(0px);
        opacity: 1;
    }
}