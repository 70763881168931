footer{
    background: var(--c-dark-highlight);
    color: var(--c-dark-bg);
    min-height: 100px;
    z-index: 0;
}

footer::before{
    background: linear-gradient(90deg, red, orange, yellow, green, indigo, violet);
    content: "";
    height: 6px;
    width: 100%;
}

@keyframes footer-anim{

}

footer .logo img{
    height: 1.6rem;
}

footer .wrapper {
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 1rem;
    row-gap: 2rem;
    align-items: center;
}

footer .wrapper .line{
    height: 0;
    width: 100%;
    border-bottom: 1px solid var(--c-dark-bg);
    display: grid;
    align-items: center;
    position:relative;
    z-index: 0;
}

footer .wrapper .line::after{
    content: "";
    width: 5px;
    height: 5px;
    background: #000;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    right: -2px;
    top: -2px;
}

footer nav{
    grid-column: 1/3;
}

footer nav ul{
    display: grid;
    gap: 1rem;
}

footer nav a{
    text-decoration: none;
    color: var(--c-dark-bg);
}

footer .cta-btns{
    align-self: start;
}

footer hr{
    border: none;
    border-bottom: 1px solid var(--c-dark-bg);
    grid-column: 1/4;
}

.copy{
    grid-column: 1/4;
    font-weight: 600;
    font-size: 12px;
    margin-bottom: .5em;
}

@media screen and (max-width: 520px) {
    footer .wrapper{
        font-size: 14px;
    }
}